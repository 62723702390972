import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import APIServices from "../../../service/APIService";
import { API } from "../../../components/constants/api_url";
import useForceUpdate from "use-force-update";
import { MultiSelect } from "primereact/multiselect";
import { Tooltip } from "primereact/tooltip";
import { Checkbox } from "primereact/checkbox";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";


const ApproverAssignment = () => {
    const [rawsitelist, setRawSiteList] = useState([])
    const indicatorList = [
        {
            "indicatorId": 1,
            "approverFrequency": 3,
            "title": "GHG Scope 1 & 2 Market-based",
            "category": 1,
            "methodology": "Sum of GHG scope 1 [tCO2e] and GHG scope 2 market-based [tCO2e]",
            "selected": false
        },
        {
            "indicatorId": 2,
            "approverFrequency": 3,
            "title": "GHG Scope 1",
            "dcfIds": [
                304,
                305
            ],
            "category": 1,
            "methodology": "Emissions from refrigerant [tCO2e] and Emissions from fuel consumption [tCO2e]",
            "selected": false
        },
        {
            "indicatorId": 5,
            "approverFrequency": 3,
            "title": "GHG Scope 2 Location-based",
            "dcfIds": [
                287
            ],
            "category": 1,
            "methodology": "(Grid electricity consumption [MWh] + renewable energy from PPA [MWh] + renewable energy from green tariff [MWh]) x Grid emission factor [tCO2e/energy unit]",
            "selected": false
        },
        {
            "indicatorId": 3,
            "approverFrequency": 6,
            "title": "Total Women in Middle and Senior Management (Managers and above)",
            "dcfIds": [
                275
            ],
            "category": 2,
            "methodology": "Women in x position (Mid-management and Senior management)  /Total people in x position (Mid-management and Senior management)",
            "selected": false
        },
        {
            "indicatorId": 4,
            "approverFrequency": 6,
            "title": "Total Women Employees",
            "dcfIds": [
                275
            ],
            "category": 2,
            "methodology": "Women in x position ( Senior management )  /Total people in x position ( Senior management )",
            "selected": false
        },
        {
            "indicatorId": 6,
            "approverFrequency": 3,
            "title": "Water Withdrawn",
            "dcfIds": [
                285
            ],
            "category": 3,
            "methodology": "Sum of water withdrawn [m3]",
            "selected": false
        },
        {
            "indicatorId": 7,
            "approverFrequency": 3,
            "title": "Water Consumed",
            "dcfIds": [
                285,
                286
            ],
            "category": 3,
            "methodology": "Water withdrawn [m3] - Water discharged [m3]",
            "selected": false
        },
        {
            "indicatorId": 8,
            "approverFrequency": 3,
            "title": "Water Discharged",
            "dcfIds": [
                286
            ],
            "category": 3,
            "methodology": "Sum of water discharged [m3]",
            "selected": false
        },
        {
            "indicatorId": 9,
            "approverFrequency": 3,
            "title": "WUE",
            "dcfIds": [
                285,
                264
            ],
            "category": 3,
            "methodology": "Water withdrawn [m3] /Total electricity consumption by customer IT [kWh]",
            "selected": false
        },
        {
            "indicatorId": 10,
            "approverFrequency": 3,
            "title": "Total Non-hazardous Waste Generated",
            "dcfIds": [
                307
            ],
            "category": 4,
            "methodology": "sum of non-hazardous waste disposed [tonnes]",
            "selected": false
        },
        {
            "indicatorId": 11,
            "approverFrequency": 3,
            "title": "Non-hazardous Waste Diversion",
            "dcfIds": [
                307
            ],
            "category": 4,
            "methodology": "sum of non-hazardous waste disposed through recycling, recovery, composting,... [tonnes]",
            "selected": false
        },
        {
            "indicatorId": 12,
            "approverFrequency": 3,
            "title": "Total Hazardous Waste Generated",
            "dcfIds": [
                297
            ],
            "category": 4,
            "methodology": "sum of hazardous waste disposed [tonnes]",
            "selected": false
        },
        {
            "indicatorId": 13,
            "approverFrequency": 3,
            "title": "Hazardous Waste Recycling",
            "dcfIds": [
                297
            ],
            "category": 4,
            "methodology": " sum of hazardous waste disposed through recycling, recovery, composting, waste-to-energy plants,... [tonnes]",
            "selected": false
        },
        {
            "indicatorId": 14,
            "approverFrequency": 6,
            "title": "Employee Training Hours",
            "dcfIds": [
                295
            ],
            "category": 2,
            "methodology": "Total training hours/total persons in the company",
            "selected": false
        },
        {
            "indicatorId": 15,
            "approverFrequency": 6,
            "title": "Parental leave",
            "dcfIds": [
                294
            ],
            "category": 2,
            "methodology": "",
            "selected": false
        },
        {
            "indicatorId": 16,
            "approverFrequency": 6,
            "title": "New Employee Hires and Employee Turnover",
            "dcfIds": [
                284
            ],
            "category": 2,
            "methodology": "",
            "selected": false
        },
        {
            "indicatorId": 17,
            "approverFrequency": 6,
            "title": "New Employee Hires and Employee Turnover - <30 years old",
            "dcfIds": [
                284
            ],
            "category": 2,
            "methodology": "",
            "selected": false
        },
        {
            "indicatorId": 18,
            "approverFrequency": 6,
            "title": "New Employee Hires and Employee Turnover - >50 years old",
            "dcfIds": [
                284
            ],
            "category": 2,
            "methodology": "",
            "selected": false
        },
        {
            "indicatorId": 19,
            "approverFrequency": 6,
            "title": "Performance review",
            "dcfIds": [
                296
            ],
            "category": 2,
            "methodology": "",
            "selected": false
        },
        {
            "indicatorId": 20,
            "approverFrequency": 3,
            "title": "Incidents of Discrimination",
            "dcfIds": [
                281
            ],
            "category": 2,
            "methodology": "",
            "selected": false
        },
        {
            "indicatorId": 21,
            "approverFrequency": 1,
            "title": "Communication and Training about Anti-corruption Policies and Procedures for Employees",
            "dcfIds": [
                311
            ],
            "category": 2,
            "methodology": "",
            "selected": false
        },
        {
            "indicatorId": 22,
            "approverFrequency": 3,
            "title": "Cybersecurity Training for Employees",
            "dcfIds": [
                309
            ],
            "category": 2,
            "methodology": "",
            "selected": false
        },
        {
            "indicatorId": 23,
            "approverFrequency": 3,
            "title": "Legal Actions for Anti-competitive Behaviour, Anti-trust and Monopoly Practices",
            "dcfIds": [
                308
            ],
            "category": 2,
            "methodology": "",
            "selected": false
        },
        {
            "indicatorId": 24,
            "approverFrequency": 1,
            "title": "Political Contribution",
            "dcfIds": [
                291
            ],
            "category": 2,
            "methodology": "",
            "selected": false
        },
        {
            "indicatorId": 25,
            "approverFrequency": 3,
            "title": "Confirmed Incidents of Corruption",
            "dcfIds": [
                289
            ],
            "category": 2,
            "methodology": "",
            "selected": false
        },
        {
            "indicatorId": 26,
            "approverFrequency": 3,
            "title": "GHG Scope 2 Market-based (not including EACs)",
            "dcfIds": [
                287
            ],
            "category": 1,
            "methodology": "Grid electricity consumption [MWh] x Grid emission factor [tCO2e/energy unit]",
            "selected": false
        },
        {
            "indicatorId": 27,
            "approverFrequency": 3,
            "title": "Energy Use",
            "dcfIds": [
                287
            ],
            "category": 5,
            "methodology": "Grid electricity consumption [MWh] + renewable energy from PPA [MWh]+ renewable energy from green tariff [MWh] + renewable energy from self generation [MWh]",
            "selected": false
        },
        {
            "indicatorId": 28,
            "approverFrequency": 3,
            "title": "Renewable Energy Factor (Not including EACs)",
            "dcfIds": [
                287
            ],
            "category": 5,
            "methodology": "Total Renewable electricity consumption from PPA, green tariff and self generation [MWh]  / Energy use [MWh] ",
            "selected": false
        },
        {
            "indicatorId": 29,
            "approverFrequency": 1,
            "title": "Renewable Energy Factor (including EACs)",
            "dcfIds": [
                268,
                287
            ],
            "category": 7,
            "methodology": "(Total Renewable electricity consumption from PPA, green tariff and self generation [MWh] + Total RECs retired [RECS])  / Energy use [MWh] ",
            "selected": false
        },
      
        {
            "indicatorId": 30,
            "approverFrequency": 1,
            "title": "Total Customer IT load",
            "dcfIds": [
                264
            ],
            "category": 2,
            "methodology": "sum of all customer IT load [MWh]",
            "selected": false
        },
        {
            "indicatorId": 31,
            "approverFrequency": 1,
            "title": "PUE",
            "dcfIds": [
                264, 287
            ],
            "category": 2,
            "methodology": "Energy Use [MWh] /Total electricity consumption by customer IT [MWh]",
            "selected": false
        }
    ]
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }, { name: 'Undefined', id: 6 }]
    const admin_data = useSelector((state) => state.user.admindetail);
    const login_data = useSelector((state) => state.user.userdetail);
    const userList_ = useSelector(state => state.userlist.userList)
    const [isEditing, setIsEditing] = useState(false);

    const [load, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [visible, setVisible] = useState(false);
    const forceUpdate = useForceUpdate();
    const [indicatorlist, setIndicatorList] = useState(indicatorList)
    const [indicatorlistbk, setIndicatorListBk] = useState(indicatorList)
    const [indicatorassdialog, setIndicatorAssDialog] = useState(false)
    const [indicatorAssignments, setIndicatorAssignments] = useState([]);
    const [indicatorAssignmentsbk, setIndicatorAssignmentsBk] = useState([]); // New state for assignments
    // New state for assignments
    const [roleData, setRoleData] = useState([])
    const entryStartDate = DateTime.fromISO(admin_data.information.startdate, { zone: 'utc' }).toLocal().toJSDate()
    const level_list = [{ name: 'Corporate', id: 0 }, { name: "Country", id: 1 }]
    const [approverList, setApproverList] = useState([])
    const [viewerList, setViewerList] = useState([])


    const [indicatorassobj, setIndicatorAssObj] = useState({
        indicatorId: null,
        locations: [],
        levelOfApproval: null,
        threshold: false,
        tvalue1: null,
        tvalue2: null

    })

    useEffect(() => {
        let uriString = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]

        }
        let uriString2 = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }



        const promise0 = APIServices.get(API.GetRole_Up(admin_data.id))
        const promise1 = APIServices.get(
            API.LocationOne_UP(admin_data.id) +
            `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`
        );
        const promise2 = APIServices.get(API.Indicator_Approver_Ass_UP(admin_data.id))
        Promise.all([promise0, promise1, promise2]).then((values) => {
            setRoleData(values[0].data)
            let indicator_list = values[2].data
            setIndicatorAssignments(indicator_list)
            setIndicatorAssignmentsBk(indicator_list)
            const shapedSite = values[1].data.map((item) => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(
                        (locationTwo) =>
                            locationTwo.locationThrees &&
                            locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            }).filter((item) => item.locationTwos && item.locationTwos.length > 0);
            setRawSiteList(shapedSite)








        })
    }, [])
    const getResponsibilityById = (ids) => {

        const responsibilityMap = userList_.reduce((map, item) => {
            map[item.id] = item?.information?.empname;
            return map;
        }, {});

        return ids.split(", ").map(id => responsibilityMap[id] || id).join(", ");

    };
    const itemTemplate = (option) => {
        console.log(option)
        return (
            <div className="flex align-items-center ">

                <div>

                    {option.id} - {option.name}</div>


            </div>
        );
    };
    const getIndicatorTitleById = (id) => {
        const indicator = indicatorList.find((item) => item.indicatorId === id);
        return indicator ? id + ' - ' + indicator.title : "Unknown";
    };
    const editVal = (item) => {


        // items.startDate = DateTime.fromISO(items.startDate, { zone: 'utc' }).toJSDate()
        // items.completionDate = DateTime.fromISO(items.completionDate, { zone: 'utc' }).toJSDate()
        let items = JSON.parse(JSON.stringify(item))
        // items.startDate = DateTime.fromISO(items.startDate, { zone: 'utc' }).toJSDate()
        console.log(items)

        // Set the state with all necessary fields
        setIndicatorAssObj({
            levelOfApproval: items.levelOfApproval,
            threshold: items.threshold,
            tvalue1: items.tvalue1,
            tvalue2: items.tvalue2,
            indicatorId: items.indicatorId,
            id: items.id
            // Important for PATCH
        });


        setIsEditing(true); // Set editing mode to true
        setIndicatorAssDialog(true); // Open the dialog
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <>


                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded navy-button mr-2"
                        onClick={() => {
                            editVal(rowData)
                        }}
                    />

                </div>

            </>
        );
    };
    const getApprovalById = (id) => {
        const approval = [{ name: 'Corporate', id: 0 }, ...level_list].find((item) => item.id === id);
        return approval ? approval.name : "Unknown";
    };
    const loaRowFilterTemplate = (options) => {

        return (
            <MultiSelect
                value={options.value}
                options={level_list}
                itemTemplate={itemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                optionValue="id"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const indicatorRowFilterTemplate = (options) => {

        return (
            <MultiSelect
                value={options.value}
                options={indicatorList.filter(x => indicatorAssignments.map(i => i.indicatorId).includes(x.indicatorId)).map(i => ({ name: i.title, id: i.indicatorId }))}
                itemTemplate={itemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                optionValue="id"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const newIndicatorAssObj = () => {
        setIndicatorAssObj({
            indicatorId: null,
            levelOfApproval: null,
            // frequencyOfApproval: null,
            threshold: false,
            tvalue1: null,
            tvalue2: null,
            locations: []

        })
        setIsEditing(false)
        setIndicatorAssDialog(true)
        setSubmitted(false)

    }
    const SaveAssignment = (userId) => {
        setSubmitted(true); // This triggers the validation messages
        let loc = indicatorassobj
        let local2 = indicatorAssignments;

        // Check if all required fields are filled, including end_date
        if (
            indicatorassobj.indicatorId !== null &&
            indicatorassobj.levelOfApproval !== null &&
            (!indicatorassobj.threshold ||
                (indicatorassobj.threshold && indicatorassobj.tvalue1 !== null && indicatorassobj.tvalue2 !== null))
        ) {
            if (isEditing && indicatorassobj.id) {
                let newObj = { ...indicatorassobj }
                delete newObj.isEndDateDisabled
                delete newObj.responsibility
                delete newObj.viewer_id
                APIServices.patch(API.Indicator_Approver_Ass_Edit(indicatorassobj.id), { ...newObj }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(indicatorAssignments))
                    let loc2 = JSON.parse(JSON.stringify(indicatorAssignmentsbk))

                    let index = loc.findIndex(i => i.id === indicatorassobj.id)
                    let index1 = loc2.findIndex(i => i.id === indicatorassobj.id)

                    if (index !== -1) {
                        loc[index] = { ...loc[index], ...newObj }
                    }
                    if (index1 !== -1) {
                        loc2[index] = { ...loc2[index1], ...newObj }
                    }
                    setIndicatorAssignments(loc);
                    setIndicatorAssignmentsBk(loc2)
                    setIndicatorAssDialog(false);
                    setSubmitted(false);

                    console.log('Before update:', local2);
                    console.log('Updating assignment:', indicatorassobj);
                });
            } else {

                // Otherwise, use POST for new assignments
                APIServices.post(API.Indicator_Approver_Ass_UP(admin_data.id), { ...indicatorassobj }).then((res) => {
                    let loc = JSON.parse(JSON.stringify(indicatorAssignments))
                    let loc2 = JSON.parse(JSON.stringify(indicatorAssignmentsbk))

                    console.log(res)
                    loc.push(res.data)
                    loc2.push(res.data)
                    setIndicatorAssignments(loc);
                    setIndicatorAssignmentsBk(loc2)

                    forceUpdate();
                    setIndicatorAssDialog(false);
                    setSubmitted(false);
                });
            }

        }
    };
    const updateIndicatorAssobj = (obj, val) => {
        // Create a new copy of the indicatorassobj
        let updatedObj = { ...indicatorassobj };
        console.log(userList_.filter(i => (roleData.find(x => x.user_id === i.id && x.roles.includes(6)))).map(i => ({ name: i.information.empname, id: i.id, locationId: roleData.filter(x => x.user_id === i.id && x.roles.includes(6)).map(y => rawsitelist.find(x => x.id === y.tier1_id)?.name || null).filter(x => x).join(',') || [] })))

        if (obj === 'levelOfApproval') {
            if (val === 0) {
                let approvers = userList_.filter(i => (roleData.find(x => x.user_id === i.id && x.roles.includes(24)))).map(i => ({ name: i.information.empname, id: i.id, label: i?.information?.empname }))
                let viewer = userList_.filter(i => (roleData.find(x => x.user_id === i.id && x.roles.includes(6)))).map(i => ({ name: i.information.empname, id: i.id, label: i?.information?.empname + ' (' + roleData.filter(x => x.user_id === i.id && x.roles.includes(6)).map(y => rawsitelist.find(x => x.id === y.tier1_id)?.name || null).filter(x => x).join(',') + ')' }))

                setApproverList(approvers)
                setViewerList(viewer)

            } else if (val === 1) {
                let approvers = userList_.filter(i => (roleData.find(x => x.user_id === i.id && x.roles.includes(6)))).map(i => ({ name: i.information.empname, id: i.id, label: i?.information?.empname + ' (' + roleData.filter(x => x.user_id === i.id && x.roles.includes(6)).map(y => rawsitelist.find(x => x.id === y.tier1_id)?.name || null).filter(x => x).join(',') + ')' }))
                let viewer = userList_.filter(i => (roleData.find(x => x.user_id === i.id && x.roles.includes(24)))).map(i => ({ name: i.information.empname, id: i.id, label: i?.information?.empname }))
                setViewerList(viewer)
                setApproverList(approvers)
            }
            // let approvers = userList_.filter(i => (roleData.find(x => x.user_id === i.id && x.roles.includes(6)))).map(i => ({ name: i.information.empname, id: i.id, locationId: roleData.filter(x => x.user_id === i.id && x.roles.includes(6)).map(y => y.tier1_id) || [] }))

        }
        // Update the desired field immutably
        updatedObj[obj] = val;

        setIndicatorAssObj(updatedObj);
    };
    return (
        <div className='col-12'>
            <div className="flex justify-content-end col-12">

                <Button disabled={load} onClick={() => { newIndicatorAssObj(); setIndicatorList(indicatorList.filter(i => !indicatorAssignments.map(x => x.indicatorId).includes(i.indicatorId))); setVisible(true); }} label="Add New" icon="pi pi-plus" className="p-button-primary" /* Add click event logic here */ />
            </div>
            <DataTable
                loading={load}

                value={indicatorAssignments}
                dataKey="id"
                filters={{ levelOfApproval: { value: null, matchMode: 'in' }, indicatorId: { value: null, matchMode: 'in' } }}

                //   header={edit === 1 && renderHeader()}
                className="p-datatable-initiatives"
                scrollable // Enables scrollable body
            //scrollHeight="400px"
            >

                <Column field="indicatorId" sortable showFilterMatchModes={false} filter showApplyButton={false} filterElement={indicatorRowFilterTemplate} header="Indicator" body={(rowData) => getIndicatorTitleById(rowData.indicatorId)} />

                <Column field="levelOfApproval" showFilterMatchModes={false} filter showApplyButton={false} filterElement={loaRowFilterTemplate} header="Level of Approval" body={(rowData) => getApprovalById(rowData.levelOfApproval)} />

                <Column field="threshold" header="Threshold %" body={(rowData) => (rowData.threshold ? (rowData.tvalue1 + ' to ' + rowData.tvalue2) : "Not Applicable")} />

                <column field="action" header="Action "
                    body={actionBodyTemplate}
                />

            </DataTable>
            <Dialog
                blockScroll={true}
                visible={indicatorassdialog}
                style={{
                    width: "75%",
                }}
                header={"Assignment of Indicator"}
                modal
                className="p-fluid"

                onHide={() => { setIndicatorAssDialog(false) }}

            >

                <div>

                    <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Tooltip target={`.tooltip-1`} position="top" />

                        <div className="col-5 flex">
                            <label
                                style={{
                                    margin: 10,
                                }}
                            >
                                Indicator
                            </label>     <i className={`material-icons flex align-items-center fs-14 tooltip-1`} data-pr-tooltip={'Select the stand-alone indicator that is to be approved'}>help</i>
                        </div>
                        <div className="col-7 ddf">
                            <Dropdown
                                style={{ width: '100%', height: '100%' }}
                                filter

                                value={indicatorassobj.indicatorId} // Set value for selected indicator
                                optionValue="indicatorId"

                                options={indicatorlist} // Use indicatorlist as dropdown options
                                onChange={(e) => updateIndicatorAssobj("indicatorId", e.value)} // Store the selected indicator in your state
                                optionLabel="title" // Show the title of the indicator
                                placeholder="Select Indicator" // Adjust placeholder to reflect indicator selection
                                disabled={isEditing}
                            />


                        </div>

                    </div>

                    {indicatorassobj.indicatorId && (

                        <>
                            <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Tooltip target={`.tooltip-2`} position="top" />
                                <div className="col-5 flex">
                                    <label
                                        style={{
                                            margin: 10,
                                        }}
                                    >
                                        Set Level of Approval
                                        <span className='mandatory'>*</span>
                                    </label>
                                    <i className={`material-icons flex align-items-center fs-14 tooltip-2`} data-pr-tooltip={'Select the organizational hierarchy level at which the approval should be made. Assigning approval at a higher level in the hierarchy automatically applies to the same indicator at all lower levels. For example, if a Data Approver is set at the Country level for a specific indicator, the approval will also cover that indicator for all cities associated with the country.'}>help</i>
                                </div>
                                <div className="col-7 ddf">
                                    <Dropdown style={{ width: '100%', height: '100%' }} value={indicatorassobj.levelOfApproval} optionValue="id" options={level_list} onChange={(e) => updateIndicatorAssobj("levelOfApproval", e.value)} optionLabel="name" placeholder="Select Level Of Approval" />

                                    {submitted && (indicatorassobj.levelOfApproval === null) &&
                                        (

                                            <small
                                                className="p-invalid"
                                                style={{
                                                    color: "red",
                                                }}
                                            >

                                                Set Level of Approval
                                            </small>

                                        )}


                                </div>


                            </div>






                            <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Tooltip target={`.tooltip-4`} position="top" />

                                <div className="col-5 flex">
                                    <Checkbox inputId="threshold" checked={indicatorassobj.threshold} onChange={e => updateIndicatorAssobj("threshold", e.checked)}
                                        style={{
                                            margin: 10,
                                        }} />
                                    <label htmlFor="threshold" className="p-checkbox-label" style={{ margin: 10 }}>
                                        Threshold %  <span className='mandatory'>*</span></label>
                                    <i className={`material-icons flex align-items-center fs-14 tooltip-4`} data-pr-tooltip={'Tick this box if you want to set upper and lower threshold percentage values to identify variances between the previous reporting period and the current one. When the computed values of the indicator exceed these thresholds, approvers will be required to provide a mandatory commentary explaining the reasons for the variation. This qualitative input helps analyze and identify special causes for the variation, thereby improving process effectiveness.'}>help</i>

                                </div>

                                <div className="col-7">
                                    {indicatorassobj.threshold === true && (
                                        <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                            <div className="col-3" style={{ width: '50%', height: '50%' }}>
                                                <div id="gasrefill" className="p-inputgroup">

                                                    <InputNumber value={indicatorassobj.tvalue1} onValueChange={(e) => updateIndicatorAssobj("tvalue1", e.value)}

                                                        placeholder="Enter %" min={-100} max={0}


                                                    />
                                                    <span className="p-inputgroup-addon">% </span>
                                                </div>

                                                {submitted && indicatorassobj.threshold && (indicatorassobj.tvalue1 === null || indicatorassobj.tvalue2 === null) && (
                                                    <small className="p-invalid" style={{ color: "red" }}>
                                                        Please fill min threshold values
                                                    </small>
                                                )}
                                            </div>
                                            <div className="col-1" style={{ textAlign: 'center' }}>
                                                TO
                                            </div>
                                            <div className="col-3" style={{ width: '50%', height: '50%' }}>
                                                <div id="gasrefill" className="p-inputgroup">

                                                    <InputNumber value={indicatorassobj.tvalue2} onValueChange={(e) => updateIndicatorAssobj("tvalue2", e.value)} placeholder="Enter %" min={0} max={100} />

                                                    <span className="p-inputgroup-addon">% </span>
                                                </div>

                                                {submitted && indicatorassobj.threshold && (indicatorassobj.tvalue1 === null || indicatorassobj.tvalue2 === null) && (
                                                    <small className="p-invalid" style={{ color: "red" }}>
                                                        Please fill max threshold values
                                                    </small>
                                                )}

                                            </div>



                                        </div>
                                    )}
                                </div>

                            </div>



                            {/* <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Tooltip target={`.tooltip-5`} position="top" />

                                <div className="col-5 flex">
                                    <label
                                        style={{
                                            margin: 10,
                                        }}
                                    >
                                        Approver Responsibility
                                        <span className='mandatory'>*</span>
                                    </label>
                                    <i className={`material-icons flex align-items-center fs-14 tooltip-5`} data-pr-tooltip={'Select the individual(s) designated to approve the data. You can assign multiple individuals, but once any one of them approves a specific indicator, no further action will be required from the others.'}>help</i>

                                </div>
                                <div className="col-7 ddf">
                                    <MultiSelect style={{ width: '100%', height: '100%' }} value={indicatorassobj.responsibility} optionValue="id" options={approverList} onChange={(e) => updateIndicatorAssobj("responsibility", e.value)} placeholder="Select Approver" filter panelClassName="hidefilter" />

                                    {submitted && (indicatorassobj.responsibility.length === 0) &&
                                        (
                                            <small
                                                className="p-invalid"
                                                style={{
                                                    color: "red"
                                                }}
                                            >

                                                Select approver(s)
                                            </small>
                                        )}

                                </div>
                            </div>

                            <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Tooltip target={`.tooltip-5`} position="top" />

                                <div className="col-5 flex">
                                    <label
                                        style={{
                                            margin: 10,
                                        }}
                                    >
                                        View Only Responsibility
                                    </label>
                                    <i className={`material-icons flex align-items-center fs-14 tooltip-5`} data-pr-tooltip={'Select the individual(s) designated to view the data. You can assign multiple individuals, no action to perform as view only'}>help</i>

                                </div>
                                <div className="col-7 ddf">
                                    <MultiSelect style={{ width: '100%', height: '100%' }} value={indicatorassobj.viewer_ids} optionValue="id" options={viewerList} onChange={(e) => updateIndicatorAssobj("viewer_ids", e.value)} placeholder="Select User" filter panelClassName="hidefilter" />



                                </div>
                            </div> */}

                        </>
                    )}




                    <div className="flex justify-content-end">
                        <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { SaveAssignment() }}>Save Assignment </Button>
                    </div>




                </div>
            </Dialog>

        </div>


    )
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ApproverAssignment, comparisonFn);